import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { Request, Response } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';
import type { StrategyOptions, StrategyHandler, Strategy } from 'o365.pwa.declaration.sw.workbox.d.ts';
import type { SyncType } from 'o365.pwa.types.ts';

declare var self: IO365ServiceWorkerGlobalScope;

declare module 'o365.pwa.declaration.sw.strategies.api.pwa.strategy.d.ts' {
    export interface IApiPwaStrategyOptions extends StrategyOptions {
        mode: SyncType;
    }

    export interface IFile {
        PrimKey: string;
        O365_PrimKey: string;
        FileName: string;
        Extension: string;
        FileSize: number;
        FileRef: string;
        PdfRef?: string;
        ThumbnailRef?: string;
    }

    export interface IProcedureOptions {
        operation: string;
        procedureName: string;
        timeout: number;
        useTransaction: boolean;
        values: {
            DeviceRef?: string;
            AppID: string;
            Type?: string;
        };
    }

    export class ApiPwaStrategy extends Strategy {
        constructor(options: IApiPwaStrategyOptions);

        _handle(request: Request, handler: StrategyHandler): Promise<Response | undefined>;
    }
}
